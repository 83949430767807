<template>
<div class="print-hotelvoucher">
    <div class="print-button">
        <Button label="Print" icon="pi pi-print" class="p-button-sm p-button-secondary" @click="print()" />
    </div>
    <div style="padding: 8mm 13mm; text-align: justify">
        <div class="p-grid">
            <div class="p-col-3" style="margin-left: 70px">
                <img alt="voucher-logo" style="width: 70%" src="../assets/images/logo-on-voucher.png" />
            </div>
            <div class="p-col-7">
                <div class="voucher-header" style="margin-left: 20px; text-align: left">
                    <span style="font-size: 11pt; font-weight: 600; color: #000">Angkor Destination Travel</span>
                    <p>P. O. Box.: 93148 | Siem Reap Angkor.</p>
                    <p>Office No. 629, Phum Mondul I, Svay Dangkum, Siem Reap Krong</p>
                    <p>Hotline: +855 (0)95 800 801 | +855 (0)12 695 295</p>
                    <p>Ph.: +855 (0)63 767 868 | Fax.: +855 (0)63 767 969</p>
                    <p>Email: info@angkordestination.com | www.angkordestination.com</p>
                </div>
            </div>
        </div>
        <div class="p-grid">
            <div style="width: 100%; text-align: center; margin-bottom: 15px; margin-top: 30px">
                <p style="font-weight: bold; font-size: 12pt">HOTEL VOUCHER</p>
            </div>
        </div>
        <div class="p-grid p-jc-end" style="margin: 0px 5px 20px 0px">
            <table style="font-weight: bold; width: 190px">
                <tr>
                    <td style="text-align: right; text-decoration: underline; background-color: #d1d1d1">Voucher no:</td>
                    <td style="width: 100px;">0</td>
                </tr>
            </table>
        </div>

        <div class="p-grid">
            <div class="p-col-12">
                <table class="border-cell">
                    <tr>
                        <td style="width: 140px; background-color: #d1d1d1; border-right: 0;">To.:</td>
                        <td style="width: 190px;">{{ hotelBookingData.hotel_name }}</td>
                        <td style="border: 0; padding-left: 20px; text-align: right">Tour ref.:</td>
                        <td style="border: 0; border-bottom: 2px solid #000; padding-left: 5px">{{ hotelBookingData.tour_code }}</td>
                    </tr>
                </table>
            </div>
            <div class="p-col-12" style="margin-top: 5px;">
                <table style="font-weight: bold; float: right; width: 195px;">
                    <tr>
                        <td style="background-color: #d1d1d1">Issued Date:</td>
                        <td style="text-align: center">{{ dayjs(new Date).format('DD-MM-YYYY') }}</td>
                    </tr>
                </table>

                <table class="table-border editable-cell">
                    <tr>
                        <td style="width: 140px; background-color: #d1d1d1">Group name:</td>
                        <td colspan="3" style="border-bottom: 1px solid #000">{{ hotelBookingData.tour_code }}</td>
                        <td style="width: 95px;"></td>
                    </tr>

                    <tr>
                        <td></td>
                        <td colspan="3" style="border-bottom: 1px solid #000"></td>
                        <td></td>
                    </tr>

                    <tr>
                        <td style="background-color: #d1d1d1">Arr. date:</td>
                        <td>{{ hotelBookingData.from_date }}</td>
                        <td style="width: 105px; background-color: #d1d1d1">ETA:</td>
                        <td style="width: 90px; font-weight: normal" contenteditable="true">{{ hotelBookingData.arrival_time }}</td>
                        <td style="width: 90px; font-weight: normal">Private bus</td>
                    </tr>
                    <tr>
                        <td style="background-color: #d1d1d1">Dep. date:</td>
                        <td>{{ hotelBookingData.to_date }}</td>
                        <td style="background-color: #d1d1d1">ETD:</td>
                        <td style="width: 90px; font-weight: normal" contenteditable="true">{{ hotelBookingData.depart_time }}</td>
                        <td style="width: 90px; font-weight: normal">Private bus</td>
                    </tr>
                    <tr>
                        <td style="background-color: #d1d1d1">Accommodation:</td>
                        <td>{{ hotelBookingData.hotel_name }}</td>
                        <td style="background-color: #d1d1d1; text-align: right">No. of pax:</td>
                        <td style="width: 90px;">{{ hotelBookingData.pax }}</td>
                    </tr>
                    <tr>
                    <td></td>
                    </tr>
                </table>
            </div>

            <div style="width: 100%; text-align: center; margin-bottom: 10px">
                <p style="font-weight: bold">Please provide the services as per this voucher.</p>
            </div>

            <div class="p-col-12">
                <table class="table-border editable-remark">
                    <tr>
                        <td style="width: 140px; text-align: right; background-color: #d1d1d1"><span style="text-decoration: underline">Room required:</span></td>
                        <td style="min-width: 40px; text-align: right">{{ hotelBookingData.sgl == null ? 0 : hotelBookingData.sgl }}</td>
                        <td style="font-weight: normal"><span style="text-decoration: underline">Single</span></td>
                        <td style="width: 110px; background-color: #d1d1d1">Term of payment:</td>
                        <td style="width: 90px;"></td>
                        <td style="width: 90px;"></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style="text-align: right">{{ hotelBookingData.twn == null ? 0 : hotelBookingData.twn }}</td>
                        <td style="font-weight: normal"><span style="text-decoration: underline">Twin</span></td>
                        <td style="text-align: right; background-color: #d1d1d1; padding-right: 8px">Cash:</td>
                        <td style="width: 90px; border-bottom: 1px solid #000; font-weight: normal">No</td>
                        <td style="width: 90px;"></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style="text-align: right">{{ hotelBookingData.dbl == null ? 0 : hotelBookingData.dbl }}</td>
                        <td style="font-weight: normal"><span style="text-decoration: underline">Double</span></td>
                        <td style="text-align: right; background-color: #d1d1d1; padding-right: 8px">Credit:</td>
                        <td style="width: 90px; border-bottom: 1px solid #000; font-weight: normal">Yes</td>
                        <td style="width: 90px;"></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style="text-align: right">{{ hotelBookingData.trp == null ? 0 : hotelBookingData.trp }}</td>
                        <td style="font-weight: normal"><span style="text-decoration: underline">Triple</span></td>
                        <td></td>
                        <td style="width: 90px;"></td>
                        <td style="width: 90px;"></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td style="text-align: right">{{ hotelBookingData.extra_bed == null ? 0 : hotelBookingData.extra_bed }}</td>
                        <td style="font-weight: normal"><span style="text-decoration: underline">Extra bed</span></td>
                        <td></td>
                        <td style="width: 90px;"></td>
                        <td style="width: 90px;"></td>
                    </tr>
                    <tr>
                        <td style="height: 10px;"></td>
                    </tr>
                    <tr>
                        <td style="text-align: right; background-color: #d1d1d1"><span style="text-decoration: underline">Remarks:</span></td>
                        <td colspan="4" style="border-bottom: 1px solid #000; font-weight: normal" contenteditable="true"></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td colspan="4" style="border-bottom: 1px solid #000; font-weight: normal" contenteditable="true"></td>
                    </tr>
                    <tr>
                        <td style="width: 105px;"></td>
                    </tr>
                </table>
            </div>
            <div style="width: 100%; margin-top: 10px; text-align: center">
                <p>Thanks for taking the best care of our customers!</p>
            </div>
            <table style="width: 20%; margin-top: 40px">
                <tr>
                    <td>Authorized signature</td>
                </tr>
                <tr>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                </tr>
                <tr>
                    <td style="border-bottom: 1px dotted"></td>
                </tr>
            </table>
        </div>
    </div>
</div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import dayjs from 'dayjs';
import BookingService from '../service/BookingService'

export default {
    setup() {
        const store = useStore()
        const route = useRoute()
        const bookingHotelID = route.query.bookinghotelid
        let hotelBookingData = ref({})

        const bookingService = ref(new BookingService())

        store.dispatch('showSideBar', false) 
        store.dispatch('showTopBar', false)

        onMounted(() => {
            getHotelBooking()
        })

        const getHotelBooking = () => {
            if(bookingHotelID != 0) {
                bookingService.value.printHotelVoucher(bookingHotelID).then(data => {
                    if(!data.errorResponse) {                        
                        hotelBookingData.value = data[0]
                    }
                })
            }
        }

        const print = () => {
            window.print()
        }

        return {
            dayjs,
            hotelBookingData,
            print,
        }
    }
}
</script>

<style lang="scss" scoped>
.print-hotelvoucher {
    top: 0;
	position: absolute;
    background-color: #fff;
	width: 720px;
	height: 100%;
	z-index: 9999;
    page-break-after: always;
}

p {
    color: #000;
    margin: 0;
    font-size: 8pt;
}
@page {
	size: A4;
	margin: 8mm 10mm 8mm 10mm;
}
@media print {
    table {
        width: 100%;
        vertical-align: bottom;
        color-adjust: exact !important;
        -webkit-print-color-adjust: exact !important;
    }

    .editable-cell {
        tr:nth-child(3) td:nth-child(4), tr:nth-child(4) td:nth-child(4){
            border: 0 !important;
        }
    }
    .editable-remark {
        tr:nth-child(6) td:nth-child(2), tr:nth-child(7) td:nth-child(2){
            border: 0 !important;
            border-bottom: 1px solid #000 !important;
        }
    }
    .print-button {
        display: none;
    }
}

.no-border td {
  border: 1px solid black;
//   font-weight: bold;
}

.table-border {
  border: 1px solid #000;
//   font-weight: bold;
}

table {
    width: 100%;
    border-spacing: 0;
    vertical-align: bottom;
    font-size: 8pt;
    color: #000;
}

table td {
    height: 18px;
    vertical-align: bottom;
    padding: 0px 3px;
}

.border-cell td {
  border: 1px solid black;
  font-weight: bold;
}

.no-border td {
  border: 1px solid black;
//   font-weight: bold;
}

.table-border {
  border: 1px solid #000;
  font-weight: bold;
}
.voucher-header {
    margin-top: 1mm;
    p {
        line-height: 1.7;
        font-size: 8pt;
    }
}
.editable-cell {
    tr:nth-child(3) td:nth-child(4), tr:nth-child(4) td:nth-child(4){
        border: 1px solid blue;
    }
}
.editable-remark {
    tr:nth-child(6) td:nth-child(2), tr:nth-child(7) td:nth-child(2){
        border: 1px solid blue;
    }
}
.print-button {
    margin: 5px 5px;
}
</style>